<template>
  <div class="mdm-top-wrapper" @mouseleave="setMenuFocus">
    <nav class="mdm-top-wrap">
      <div class="mdm-top">
        <!-- logo -->
        <router-link to="/dashboard/operation">
          <div class="mdm-top-logo-wrapper">
            <a class="mdm-top-logo" href="#"></a>
            <!--2020-12-02 임시변경함-->
            <!--tnxrcc 로고-->
            <!--<a class="mdm-top-logo-temp" href="#"></a>-->
          </div>
        </router-link>

        <!-- 새 메뉴 html (같은구조) -->
        <div class="mdm-top-menu-wrapper">
          <ul class="mdm-top-menu-ul">
            <li
              v-for="(item, index) in menuList"
              :id="menuId(item.linkPath)"
              :key="index"
              :class="[
                'dropdown mdm-top-menu-li',
                `${item.menuNo}`,
                {
                  'mdm-top-menu-selected':
                    item.menuNo === selectedMenu.upMenuNo,
                },
              ]"
              ref="topMenu"
            >
              <a
                class="dropdown-toggle"
                data-toggle="dropdown"
                @click="goMenu(item.linkPath)"
                @mouseover="showSubMenu(item.menuNo)"
              >
                <span style="top:2px">{{ item.menuName }}</span>
              </a>
              <ul class="dropdown-menu mdm-top-menu-inner-ul">
                <li
                  v-for="(subItem, index) in item.subMenuList"
                  :key="index"
                  :class="[
                    'mdm-top-menu-inner',
                    `${subItem.menuNo}`,
                    {
                      'sub-menu-selected':
                        subItem.menuNo === selectedMenu.menuNo,
                    },
                  ]"
                  ref="subMenu"
                >
                  <a @click="goMenu(subItem.linkPath)">{{
                    subItem.menuName
                  }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- 사용자 정보 -->
        <div class="mdm-top-status-wrapper">
          <ul class="mdm-top-status-ul">
            <!-- 사용자 정보 -->
            <li class="mdm-top-status-ul-user">
              <span class="top-user-icon"></span>
              <span class="top-user-name" style="margin: 0 5px">{{
                userId
              }}</span>
              <span class="top-user-name">{{ userName }}</span>
              <button class="mdm-top-manager-logout-button" @click="logout()">
                Logout
              </button>
            </li>
          </ul>
        </div>
        <!-- 언어 설정 -->
        <div style="position: relative; float: right;" v-if="isGlobal">
          <select
            class="select-lang"
            v-model="$i18n.locale"
            @change="changeLocale($i18n.locale)"
          >
            <option
              v-for="(availableLocale, index) in $i18n.availableLocales"
              :key="index"
              :value="availableLocale"
            >
              {{ availableLocale }}
            </option>
          </select>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: this.$store.state.auth.userName,
      userId: this.$store.state.auth.userId,
      menuList: this.$store.state.auth.menuList,
      selectedMenu: '',
      isGlobal: false,
    };
  },
  computed: {
    currentUpperPage() {
      const splitPathName = window.location.pathname.split('/');
      return splitPathName[2];
    },
    currentPage() {
      const splitPathName = window.location.pathname.split('/');
      return `${splitPathName[2]}/${splitPathName[3]}`;
    },
    pathName() {
      return this.$store.state.pathName;
    },
  },
  watch: {
    pathName() {
      this.setSelectedMenu(this.pathName);
    },
  },
  methods: {
    // 대메뉴 마우스오버시 서브메뉴 보이기 및 CSS 변경 -> jquery 사용하지 않도록 수정
    showSubMenu(menuNo) {
      const topMenu = this.$refs.topMenu;
      topMenu.forEach(item => {
        item.classList.toggle(
          'mdm-top-menu-selected',
          item.classList.contains(menuNo),
        );
      });
    },
    // 메뉴 마우스 올렸다가 내릴 경우 원래 메뉴 포커싱 -> jquery 사용하지 않도록 수정
    setMenuFocus() {
      const topMenu = this.$refs.topMenu;
      topMenu.forEach(item => {
        item.classList.toggle(
          'mdm-top-menu-selected',
          item.classList.contains(this.selectedMenu.upMenuNo),
        );
      });

      const subMenu = this.$refs.subMenu;
      subMenu.forEach(item => {
        item.classList.toggle(
          'sub-menu-selected',
          item.classList.contains(this.selectedMenu.menuNo),
        );
      });
    },
    menuId(path) {
      return path.split('/')[1];
    },
    async logout() {
      await this.$store.dispatch('auth/LOGOUT').then(({ data }) => {
        this.$dialogs.alert(data.message).then(() => {
          this.$router.push('/login');
        });
      });
    },
    goMenu(path) {
      if (this.$route.path != path) {
        //this.setSelectedMenu(path);
        this.$router.push(path).then(() => {});
      } else {
        this.$router.go(this.$router.currentRoute);
      }
    },
    // 선택된 메뉴 화면에 표시
    setSelectedMenu(path) {
      // 선택된 메뉴 확인
      const pathName = window.location.pathname;
      this.menuList.forEach(({ subMenuList }) => {
        if (subMenuList) {
          subMenuList.forEach(subItem => {
            if (path) {
              if (subItem.menuLevel == 2 && path == subItem.linkPath) {
                this.selectedMenu = subItem;
              }
            } else {
              if (
                subItem.menuLevel == 2 &&
                pathName.endsWith(subItem.linkPath)
              ) {
                this.selectedMenu = subItem;
              }
            }
          });
        }
      });
    },
    async changeLocale(locale) {
      localStorage.setItem('lang', locale);
      await this.$store.dispatch('auth/FETCH_MENU_LIST').then(() => {
        this.menuList = this.$store.state.auth.menuList;
      });
    },
  },
  created() {
    this.isGlobal = `${process.env.VUE_APP_GLOBAL}` === 'true' ? true : false;
  },
  mounted() {
    // 선택된 메뉴 화면에 표시
    this.setSelectedMenu();
  },
};
</script>

<style>
.select-lang {
  border: 1px solid #ddd;
  padding-right: 15px;
  background: #fff;
  height: 38px;
  font-size: 14px;
  padding-left: 10px;
  margin-top: 10px;
}
</style>
