<template>
  <v-main>
    <four-panel
      :topLeftPanelWidth="fourPanel.topLeftPanelWidth"
      :topRightPanelWidth="fourPanel.topRightPanelWidth"
      :bottomLeftPanelWidth="fourPanel.topLeftPanelWidth"
      :bottomRightPanelWidth="fourPanel.topRightPanelWidth"
      :topLeftPanelTitle="topLeftPanelTitle"
      :topRightPanelTitle="topRightPanelTitle"
      :bottomLeftPanelTitle="bottomLeftPanelTitle"
      :bottomRightPanelTitle="bottomRightPanelTitle"
    >
      <template #topLeftPanel>
        <div class="panel" style="height:342px">
          <div class="chart-area">
            <div id="chart1">
              <chart :options="chart1"></chart>
            </div>
          </div>
        </div>
      </template>
      <template #topRightPanel>
        <div class="panel" style="height:342px">
          <div class="chart-area">
            <div id="chart2">
              <chart :options="chart2"></chart>
            </div>
          </div>
        </div>
      </template>
      <template #bottomLeftPanel>
        <div class="panel">
          <div class="chart-area">
            <div id="chart3">
              <chart :options="chart3"></chart>
            </div>
          </div>
        </div>
      </template>
      <template #bottomRightPanel>
        <div class="panel">
          <div class="chart-area">
            <div id="chart4">
              <chart :options="chart4"></chart>
            </div>
          </div>
        </div>
      </template>
    </four-panel>
  </v-main>
</template>
<script>
import { Chart } from 'highcharts-vue';
import { getChartsList } from '@/api/dashboardApi';
import FourPanel from '@/layouts/FourPanel.vue';

export default {
  components: {
    FourPanel,
    Chart,
  },
  data: () => ({
    fourPanel: {
      topLeftPanelWidth: '923',
      topRightPanelWidth: '462',
      bottomLeftPanelWidth: '923',
      bottomRightPanelWidth: '462',
    },
    auto_reload: false,
    //WEB_AUTO_REFRESH_CYCLE_SEC_DEFAULT_VALUE = 10 ;
    auto_reload_delay: 10 * 30000,
    auto_reload_func: null,
  }),
  methods: {
    callChart2(data) {
      if (data && data.length > 0) {
        this.$store.commit('charts/setChart2Options', data);
      }
    },
    callChart3(data) {
      if (data && data.length > 0) {
        this.$store.commit('charts/setChart3Options', data);
      }
    },
    callChart4(data) {
      if (data && data.length > 0) {
        this.$store.commit('charts/setChart4Options', data);
      }
    },
    /**
     * 기본 차트 호출
     * - 서비스별 패킷 전송건수
     * - PHR 정보 수집현황
     * - 서비스별 가입자수
     * @returns
     */
    async callCharts() {
      await getChartsList().then(res => {
        const { chart2, chart3, chart4 } = res.data;
        this.callChart2(chart2);
        this.callChart3(chart3);
        this.callChart4(chart4);
      });
    },
  },
  created() {
    this.callCharts();
    this.auto_reload = true;
    this.auto_reload_func = setInterval(() => {
      this.callCharts();
    }, this.auto_reload_delay);

    let data = [],
      time = new Date().getTime();
    for (let i = -19; i <= 0; i += 1) {
      data.push({
        x: time + i * 5000,
        y: 0,
      });
    }
    console.log('OperationPage Created');
    console.log(process.env);
    this.$store.commit('charts/setChart1Options', data);
  },
  computed: {
    chart1() {
      return this.$store.getters['charts/getChart1Options'];
    },
    chart2() {
      return this.$store.getters['charts/getChart2Options'];
    },
    chart3() {
      return this.$store.getters['charts/getChart3Options'];
    },
    chart4() {
      return this.$store.getters['charts/getChart4Options'];
    },
    topLeftPanelTitle() {
      return this.$i18n.t('dashboard.topLeftPanel-title');
    },
    topRightPanelTitle() {
      return this.$i18n.t('dashboard.topRightPanel-title');
    },
    bottomLeftPanelTitle() {
      return this.$i18n.t('dashboard.bottomLeftPanel-title');
    },
    bottomRightPanelTitle() {
      return this.$i18n.t('dashboard.bottomRightPanel-title');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.$store.commit('charts/setChart1OptionsLocale');
      this.$store.commit('charts/setChart2OptionsLocale');
      this.$store.commit('charts/setChart3OptionsLocale');
      this.$store.commit('charts/setChart4OptionsLocale');
    },
  },
  destroyed() {
    // charts x data reset
    this.$store.commit('charts/setChart1Options', []);
    this.$store.commit('charts/setChart2Options', []);
    this.$store.commit('charts/setChart3Options', []);
    this.$store.commit('charts/setChart4Options', []);
    // router 이동 시 charts stop
    clearInterval(this.auto_reload_func);
    this.$store.commit('charts/setStopChart1Options', 'stop');
  },
};
</script>

<style lang="scss" scoped></style>
