<template>
  <section>
    <div class="panel-contents">
      <!-- 위 좌측 패널  -->
      <div :class="`col-${topLeftPanelWidth}`">
        <div class="panel">
          <h4 class="panel-top-title">{{ topLeftPanelTitle }}</h4>
          <slot name="topLeftPanel" />
        </div>
      </div>
      <!-- 위 우측 패널  -->
      <div :class="`col-${topRightPanelWidth}`">
        <div class="panel">
          <h4 class="panel-top-title">{{ topRightPanelTitle }}</h4>
          <slot name="topRightPanel" />
        </div>
      </div>
    </div>
    <div class="panel-contents">
      <!-- 아래 좌측 패널  -->
      <div :class="`col-${bottomLeftPanelWidth}`">
        <div class="panel">
          <h4 class="panel-top-title">{{ bottomLeftPanelTitle }}</h4>
          <slot name="bottomLeftPanel" />
        </div>
      </div>
      <!-- 아래 우측 패널 -->
      <div :class="`col-${bottomRightPanelWidth}`">
        <div class="panel">
          <h4 class="panel-top-title">{{ bottomRightPanelTitle }}</h4>
          <slot name="bottomRightPanel" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    topLeftPanelWidth: {
      type: String,
      // default: '1050',
    },
    topRightPanelWidth: {
      type: String,
      // default: '337',
    },
    bottomLeftPanelWidth: {
      type: String,
      // default: '1050',
    },
    bottomRightPanelWidth: {
      type: String,
      // default: '337',
    },
    topLeftPanelTitle: {
      type: String,
      required: true,
    },
    topRightPanelTitle: {
      type: String,
      required: true,
    },
    bottomLeftPanelTitle: {
      type: String,
      required: true,
    },
    bottomRightPanelTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
